<template>
  <div class="wrapper">
    <b-container class="wrapper">
      <div class="mt-4 mb-4 wrapper">
        <b-row>
          <b-col>
            <p class="TextBlack32">{{ $t("taklif") }}</p>
            <!-- <p class="TextBlack17">Вся актуальная информация об образовании в Узбекистане</p> -->
          </b-col>
          <b-col class="text-right">
            <b-button @click="Create" class="buttonTaklif pr-5 pl-5 mt-1">
              <p class=" m-1 ">
                <svg
                  class="mb-1 mr-2"
                  xmlns="http://www.w3.org/2000/svg"
                  width="18"
                  height="18.414"
                  viewBox="0 0 18 18.414"
                >
                  <path
                    id="Контур_520"
                    data-name="Контур 520"
                    d="M64,16l-8,8v8h8l8-8-8-8"
                    transform="translate(-55 -14.586)"
                    fill="none"
                    stroke="#fff"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                  />
                </svg>
                {{ $t("write") }}
              </p>
            </b-button>
          </b-col>
        </b-row>
        <!-- </div>
      <div class="mt-3 taklifSize" style="min-height:200px"> -->
        <b-row>
          <b-col
            v-for="(item, index) in items"
            :key="index"
            sm="12"
            md="6"
            lg="4"
          >
            <b-card-group deck>
              <b-card class="taklifCard m-3" style="min-height:170px">
                <div class="ml-3">
                  <p>{{ $t("Таклиф") }}</p>
                  <b-card-text
                    ><p
                      style="height: 120px;width: 100%; text-align:left;  overflow-y: hidden;"
                      class="mt-3"
                    >
                      {{ item.text }}
                    </p></b-card-text
                  >
                  <p>
                    <svg
                      class="mb-2 mr-2"
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="25.999"
                      viewBox="0 0 20 25.999"
                    >
                      <path
                        id="Account_Icon"
                        data-name="Account Icon"
                        d="M-6204-2842a4.005,4.005,0,0,1-4-4,4,4,0,0,1,4-4h12a4,4,0,0,1,4,4,4,4,0,0,1-4,4Zm-2-4a2,2,0,0,0,2,2h12a2,2,0,0,0,2-2,2,2,0,0,0-2-2h-12A2,2,0,0,0-6206-2846Zm0-14a8.008,8.008,0,0,1,8-8,8.008,8.008,0,0,1,8,8,8.01,8.01,0,0,1-8,8A8.01,8.01,0,0,1-6206-2860Zm2,0a6.007,6.007,0,0,0,6,6,6.007,6.007,0,0,0,6-6,6.006,6.006,0,0,0-6-6A6.006,6.006,0,0,0-6204-2860Z"
                        transform="translate(6208 2868)"
                        fill="#ced5e0"
                      />
                    </svg>
                    {{ item.senderfio }}
                  </p>
                  <b-row>
                    <b-col>
                      <p>
                        <svg
                          class="mb-1 mr-2"
                          xmlns="http://www.w3.org/2000/svg"
                          width="24.001"
                          height="16.001"
                          viewBox="0 0 24.001 16.001"
                        >
                          <path
                            id="View_Icon"
                            data-name="View Icon"
                            d="M-6148-2420a10.1,10.1,0,0,1-4.67-1.25,18.061,18.061,0,0,1-3.814-2.75,25.529,25.529,0,0,1-3.515-4,25.416,25.416,0,0,1,3.515-4,18.051,18.051,0,0,1,3.814-2.75A10.1,10.1,0,0,1-6148-2436a10.1,10.1,0,0,1,4.671,1.25,18.048,18.048,0,0,1,3.814,2.75,25.531,25.531,0,0,1,3.516,4,25.427,25.427,0,0,1-3.516,4,18.054,18.054,0,0,1-3.814,2.75A10.1,10.1,0,0,1-6148-2420Zm0-12a4,4,0,0,0-4,4,4,4,0,0,0,4,4,4,4,0,0,0,4-4A4,4,0,0,0-6148-2432Z"
                            transform="translate(6160 2436)"
                            fill="#ced5e0"
                          />
                        </svg>
                        {{ item.viewcount }}
                      </p>
                    </b-col>
                    <b-col style="cursor: pointer;">
                      <p @click="Edit(item)">
                        <svg
                          class="mb-1 mr-2"
                          xmlns="http://www.w3.org/2000/svg"
                          width="24.001"
                          height="15.999"
                          viewBox="0 0 24.001 15.999"
                        >
                          <path
                            id="Comment_Icon"
                            data-name="Comment Icon"
                            d="M-6185-2852h-17a6.007,6.007,0,0,1-6-6v-4a6.007,6.007,0,0,1,6-6h12a6.007,6.007,0,0,1,6,6v9A1,1,0,0,1-6185-2852Zm-18.5-6a.5.5,0,0,0-.5.5.5.5,0,0,0,.5.5h15a.5.5,0,0,0,.5-.5.5.5,0,0,0-.5-.5Zm0-5a.5.5,0,0,0-.5.5.5.5,0,0,0,.5.5h15a.5.5,0,0,0,.5-.5.5.5,0,0,0-.5-.5Z"
                            transform="translate(6208 2868)"
                            fill="#ced5e0"
                          />
                        </svg>
                        <!-- 456 -->
                      </p>
                    </b-col>
                  </b-row>
                </div>

                <b-modal :id="'Text' + item.id" size="lg" hide-footer>
                  {{ item.text }}
                  <template #modal-header>
                    {{ item.senderfio }}
                  </template>
                  <b-row class="mt-3 text-right">
                    <b-col>
                      <b-button
                        @click="$bvModal.hide('Text' + item.id)"
                        variant="danger"
                        size="sm"
                        >{{ $t("Back") }}</b-button
                      >
                    </b-col>
                  </b-row>
                </b-modal>
              </b-card>
            </b-card-group>
          </b-col>
        </b-row>
      </div>

      <!-- <div>
      <b-modal size="lg" hide-footer v-model="modalComment">
        <template #modal-header>
          {{ $t("Izoh") }}
        </template>
        <b-row>
          <b-col>
            <div>
              <label>{{ $t("Date") }}</label>
              <date-picker
                v-model="filter.date"
                style="width:100%"
                size="sm"
                lang="ru"
                :placeholder="$t('OnDate')"
                value-type="format"
                format="DD.MM.YYYY"
              ></date-picker>
            </div>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <div>
              <label>{{ $t("Таклиф:") }}</label>
              <b-form-textarea
                v-model="Offer.text"
                rows="8"
                max-rows="16"
                placeholder="Enter your name"
              ></b-form-textarea>
            </div>
          </b-col>
        </b-row>

        <b-row>
          <b-col class="mt-3 text-right">
            <b-button @click="send" variant="success" size="sm">{{
              $t("Send")
            }}</b-button>
          </b-col>
          <b-col class="mt-3 text-left">
            <b-button
              @click="modalComment = false"
              variant="danger"
              size="sm"
              >{{ $t("Back") }}</b-button
            >
          </b-col>
        </b-row>
      </b-modal>
    </div> -->

      <b-row>
        <b-col>
          <div class="pb-4 mb-4 mt-3">
            <div style="float:left;font-size:12px">
              {{ $t("RecordsFrom") }} {{ this.firstNumber }} {{ $t("by") }}
              {{ this.lastNumber }} {{ $t("of") }}
              {{ this.totalRows }}
            </div>
            <div style="float:left" class="ml-2 mr-2 mt-n1">
              <b-form-select
                v-model="filter.PageLimit"
                id="perPageSelect"
                size="sm"
                :options="filter.pageOptions"
              ></b-form-select>
            </div>
            <div style="float:left;font-size:12px">
              {{ $t("RecordsPerPage") }}
            </div>
            <div style="float:right">
              <b-pagination
                v-model="filter.PageNumber"
                :total-rows="totalRows"
                :per-page="filter.PageLimit"
                align="right"
                size="sm"
                class="my-0"
              ></b-pagination>
            </div>
          </div>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>
<script>
import OfferService from "@/services/Offer.service";
// import RegulationsService from "@/services/Regulations.service";
import swal from "sweetalert";
export default {
  data() {
    return {
      modalShow: false,
      modalComment: false,
      Offer: {},
      items: [],

      generateErr: false,
      filter: {
        Search: "",
        Lang: "",
        SortColumn: "",
        OrderType: "asc",
        Offset: "",
        PageNumber: 1,
        PageLimit: 20,
        IsStartPageFromZero: false,
        pageOptions: [5, 10, 15, 20, 25, 50, 100],
      },
      totalRows: 0,
    };
  },
  created() {
    this.Refresh();
    // RegulationsService.GetList(
    //   this.filter.Search,
    //   this.filter.Lang,
    //   this.filter.SortColumn,
    //   this.filter.OrderType,
    //   this.filter.Offset,
    //   this.filter.PageNumber,
    //   this.filter.PageLimit,
    //   this.filter.IsStartPageFromZero
    // ).then((res) => {
    //   this.items = res.data.rows;
    //   this.totalRows = res.data.total;
    // });
  },
  computed: {
    firstNumber() {
      return this.totalRows > 0
        ? (this.filter.PageNumber - 1) * this.filter.PageLimit + 1
        : 0;
    },
    lastNumber() {
      if (this.totalRows < this.filter.PageLimit) {
        return this.totalRows;
      } else {
        if (this.filter.PageNumber * this.filter.PageLimit > this.totalRows) {
          return this.totalRows;
        } else {
          return this.filter.PageNumber * this.filter.PageLimit;
        }
      }
    },
  },

  methods: {
    Edit(item) {
      this.$router.push({
        name: "show",
        params: { id: item.id },
      });
    },
    Openmodal(item) {
      this.$bvModal.show(item.id);
    },
    // send() {
    //   OfferService.AddComment(this.Offer)
    //     .then((res) => {
    //       swal({
    //         title: "Good job!",
    //         text: "You clicked the button!",
    //         icon: "success",
    //         button: "Aww yiss!",
    //       });
    //       this.$router.push({
    //         name: "taklif",
    //       });
    //     })
    //     .catch(() => {
    //       this.makeToast(
    //         error.response.data.error,
    //         this.$t("message"),
    //         "danger"
    //       );
    //       swal("Oops", "Something went wrong!", "error");
    //     });
    // },
    Create() {
      this.$router.push({
        name: "izoh",
        params: { id: 0 },
      });
    },
    Refresh() {
      OfferService.GetList(
        this.filter.Search,
        this.filter.Lang,
        this.filter.SortColumn,
        this.filter.OrderType,
        this.filter.Offset,
        this.filter.PageNumber,
        this.filter.PageLimit,
        this.filter.IsStartPageFromZero
      ).then((res) => {
        // this.isBusy = false;
        this.items = res.data.rows;
        this.totalRows = res.data.total;
      });
    },
  },
};
</script>
<style lang="scss" scoped></style>
